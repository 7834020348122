// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

export async function axios(options: { url: string; [key: string]: any }) {
  return request(options.url, { ...options });
}

/** 获取当前的用户 GET /api/currentUser */
export async function currentUser() {
  return request<API.CurrentUser>('/auth/me');
}

export async function authStatus() {
  return request<API.TokenResult>('/auth/status');
}

export async function logout() {
  return request('/auth/logout', {
    method: 'POST',
  });
}

export async function formLogin(data: { username: string; password: string }) {
  return request('/auth/form-login', {
    method: 'POST',
    data,
  });
}

export async function dingTalkLogin(data: { authCode: string }) {
  return request('/auth/dingtalk-login', {
    method: 'POST',
    data,
  });
}

export async function resetPassword(data: { password: string }) {
  return request('/auth/reset-password', {
    method: 'POST',
    data,
  });
}

export async function getGameCodes(topic?: string) {
  let  url = '/index/game_code';
  if (topic) {
    url += `?topic=${encodeURIComponent(topic)}`;
  }
  return request<string[]>(url);
}

export async function getGameLifeCircle() {
  return request<string[]>('/index/game_life_circle');
}

export async function getSites() {
  return request<
    Record<'public' | 'private' | 'quick', { title: string; desc: string; url: string }[]>
  >('/index');
}

export async function getAccessPages(options?: { [key: string]: any }) {
  return request('/index/access/pages', {
    method: 'GET',
    ...(options || {}),
  });
}

export async function clearCache(
  params: {
    namespace?: string;
    key?: string;
  },
  options?: { [key: string]: any },
) {
  return request<{ count: number; deleted_keys: string[]; deleted_successfully: string[] }>(
    '/cache/clear',
    {
      method: 'GET',
      params,
      ...(options || {}),
    },
  );
}

export async function getLastedVersion() {
  return request('/public/git_versions/leyidc/lasted_version');
}

export async function getAnnouncement() {
  return request('/public/announcement');
}

export async function ping() {
  return request('/public/ping');
}

export async function exception400() {
  return request('/public/400');
}

export async function exception401() {
  return request('/public/401');
}

export async function exception403() {
  return request('/public/403');
}

export async function exception404() {
  return request('/public/404');
}

export async function exception500() {
  return request('/public/500');
}
