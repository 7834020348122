import { proxy, subscribe } from 'valtio';

const storeKey = 'recentPages';

type RecentPageType = Record<string, [string, number, boolean, number]>; // [name, count, isFavorite, lastTime]

export const recentPagesState: RecentPageType = proxy(
  JSON.parse(localStorage.getItem(storeKey) || '{}'),
);

subscribe(recentPagesState, () => {
  localStorage.setItem(storeKey, JSON.stringify(recentPagesState));
});

export const addRecentPage = (path: string, name: string) => {
  if (recentPagesState[path] == null) {
    recentPagesState[path] = [name, 0, false, Date.now()];
  } else {
    recentPagesState[path][1] += 1;
    recentPagesState[path][3] = Date.now();
  }
};

export const handleFavoritePage = (path: string) => {
  if (recentPagesState[path] != null) {
    recentPagesState[path][2] = !recentPagesState[path][2];
  }
};
