import moment from 'moment';
import * as R from 'ramda';
import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Icon, Label, Message } from 'semantic-ui-react';
import { useInterval } from '../hooks/useInterval';

export interface IPageInfo {
  page_task?: boolean;
  page_preview?: boolean;
  page_version?: string;
  page_author?: string;
  page_description?: string;
  page_detail_link?: string;
  page_extra?: { [k: string]: any };
  // info state maybe use previous, add check path to check
  check_path?: string;
}

export interface IPageTask {
  airflow_dag?: string;
  airflow_task?: string;
  task_status?: string;
  task_run?: string;
  task_predict?: string;
  task_finish?: string;
}

export const queryPageInfo = (path: string, axios: any) => (): Promise<IPageInfo> =>
  axios({
    method: 'post',
    url: path + '/info',
  }).catch((_e: any) => {
    return axios({
      method: 'get',
      url: path + '/info',
    }).catch((e: any) => {
      console.error(e);
      return {};
    });
  });

export const queryPageTask = (path: string, axios: any) => (): Promise<IPageTask> =>
  axios({
    method: 'post',
    url: path + '/task',
  }).catch((_e: any) => {
    return axios({
      method: 'get',
      url: path + '/task',
    }).catch((e: any) => {
      console.error(e);
      return {};
    });
  });

export interface IPageBar {
  axiosInstance: any;
  routePath?: string;
  intervalSeconds?: number;
  updateInfo?: (info: IPageInfo) => void;
}

const PageBar: React.FC<IPageBar> = ({
  routePath,
  axiosInstance,
  intervalSeconds = 600,
  updateInfo,
}) => {
  const pathKey = routePath || window.location.pathname.replace(/-/g, '_');
  const getPageInfo = queryPageInfo(pathKey, axiosInstance);
  const getPageTask = queryPageTask(pathKey, axiosInstance);
  const [info, setInfo] = React.useState<IPageInfo>({});
  const [task, setTask] = React.useState<IPageTask>({});
  const fetchTaskStat = React.useCallback(() => {
    getPageTask()
      .then((r) => {
        setTask(r);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);
  React.useEffect(() => {
    if (pathKey?.split('/')?.length > 2) {
      getPageInfo()
        .then((r) => {
          if (!R.isNil(r) && !R.isEmpty(r)) {
            setInfo(r);
            updateInfo?.(r);
            r.page_task && fetchTaskStat();
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, []);

  useInterval(fetchTaskStat, info.page_task ? intervalSeconds * 1000 : null); // 10mins

  let text: string = '无任务计划';
  let color = 'teal';
  if (task.task_status === 'done') {
    text =
      moment(task.task_run).format('YYYY-MM-DD') +
      ' 日数据已在本地时间 ' +
      moment(task.task_finish).local().format('YYYY-MM-DD HH:mm:ss') +
      ' 处理完';
  } else if (task.task_status === 'wait') {
    color = 'olive';
    text =
      moment(task.task_run).format('YYYY-MM-DD') +
      ' 日数据等待中,预计会在本地时间 ' +
      moment(task.task_predict).local().format('YYYY-MM-DD HH:mm:ss') +
      ' 完成';
  }
  return R.isEmpty(info) ? (
    <div />
  ) : (
    <Message size="tiny" style={{ borderRadius: 0 }}>
      {info.page_detail_link ? (
        <Label size="tiny" color="blue" as="a" href={info.page_detail_link} target="_blank">
          <Icon name="linkify" />
          文档
        </Label>
      ) : (
        ''
      )}
      {info.page_version ? (
        <Label size="tiny">
          {info.page_preview ? '预览' : ''}版本：{info.page_version}
        </Label>
      ) : (
        ''
      )}
      <Label size="tiny">作者：{info.page_author}</Label>
      <Label size="tiny">说明：{info.page_description}</Label>
      {info.page_task ? (
        <Label size="tiny" color={color as any} style={{ float: 'right' }}>
          任务：{text}
        </Label>
      ) : (
        ''
      )}
    </Message>
  );
};

export default PageBar;
