import HeaderContent from '@/components/HeaderContent';
import RightContent from '@/components/RightContent';
import PageBar from '@/components/report/PageBar';
import {
  authStatus,
  axios,
  getAccessPages,
  getAnnouncement,
  currentUser as queryCurrentUser,
} from '@/services';
import { filterByMenuData, generateRoutesMap, routesFilter } from '@/utils/routes';
import { LeftOutlined, SearchOutlined } from '@ant-design/icons';
import type { Settings as LayoutSettings, MenuDataItem } from '@ant-design/pro-layout';
import { BrowserTracing } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import type { IRoute, RequestConfig, RunTimeLayoutConfig } from '@umijs/max';
import { Link } from '@umijs/max';
import { Button, Input, Space } from 'antd';
import defaultSettings from '../config/defaultSettings';
import { errorConfig } from './requestErrorConfig';
import { addRecentPage } from './store/recentPages';

const isDev = process.env.NODE_ENV === 'development';
const sentryDSN = {
  'leyidc.simpysam.com': 'https://f214ce8b965c7c7422c571eaf3b58717@sentry.leyidc.net/4',
  // 'www.leyidc.net': 'https://2c0ec94cda614dac896620dd18fd3afd@sentry.leyidc.net/4',
};
const sentryEnv = {
  'leyidc.simpysam.com': 'leyidc-prod',
  'www.leyidc.net': 'leyidc-stag',
};
const hostname = window.location.hostname;

const loginPath = '/login';
let currentVersion = '';
let routesMap: Record<string, string> = {};
let accessRouteKeys: string[] | null = null;

async function checkAndUpdateVersion() {
  // 获取当前时间
  const currentTime = new Date();
  const currentUTCHour = currentTime.getUTCHours();

  // 检查当前时间是否在目标时间范围内
  if (currentUTCHour >= 20 && currentUTCHour < 21) {
    // 执行版本检查逻辑
    const response = await fetch('/version.txt');
    if (!response.ok) {
      console.error('fetch latest version.txt failed');
    }
    const latestVersion = await response.text();

    const isLatestVersion = latestVersion === currentVersion;
    // 如果不是最新版本，刷新页面
    if (!isLatestVersion) {
      window.location.reload();
    }
  }
}

if (!isDev) {
  const response = await fetch('/version.txt');
  if (!response.ok) {
    console.error('fetch current version.txt failed');
  }
  currentVersion = await response.text();
  // 定时器，每小时检查一次
  setInterval(checkAndUpdateVersion, 60 * 60 * 1000);
}

/**
 * 初始化 Sentry
 */
if (hostname in sentryDSN) {
  Sentry.init({
    dsn: sentryDSN[hostname],
    environment: sentryEnv[hostname],
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,

    // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
      'Request failed with status code 401',
      'Request failed with status code 404',
    ],
  });
  console.log('init', hostname);
}

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  pathname?: string;
  menuKeyWord?: string;
  pageInfo?: PageInfoModel;
  settings?: Partial<LayoutSettings>;
  currentUser?: API.CurrentUser;
  loading?: boolean;
  fetchUserInfo?: () => Promise<API.CurrentUser | undefined>;
  fetchAnnouncement?: () => Promise<string>;
}> {
  const fetchUserInfo = async () => {
    if (isDev) {
      return {
        username: 'leyidc',
        name: 'leyidc',
        nick: 'leyidc',
        email: 'leyidc@leyinetwork.com',
        avatar:
          'https://www.cegeka.com/hubfs/Belgium%20and%20Netherlands/02%20-%20Website%20pages/Data%20Intelligence/Visual%20Data%20Intelligence%202.png',
      };
    }
    try {
      const [userInfo, tokenResult] = await Promise.all([queryCurrentUser(), authStatus()]);
      const { access_token } = tokenResult;
      localStorage.setItem('token', access_token);
      return userInfo;
    } catch (error) {
      window.location.assign(
        `${window.location.origin}${loginPath}?redirect_url=${window.location.href}`,
      );
    }
    return undefined;
  };
  const fetchAnnouncement = async () => {
    if (isDev) {
      return '';
    }
    try {
      return await getAnnouncement();
    } catch (error) {
      console.error(error);
    }
    return '';
  };
  // 如果不是登录页面，执行
  if (window.location.pathname.replace(/\/+$/, '') !== loginPath) {
    const currentUser = await fetchUserInfo();
    return {
      fetchUserInfo,
      fetchAnnouncement,
      currentUser,
      settings: defaultSettings,
    };
  }
  return {
    fetchUserInfo,
    fetchAnnouncement,
    settings: defaultSettings,
  };
}

// patchClientRoutes 文档 https://umijs.org/docs/api/runtime-config#patchclientroutes-routes-
export function patchClientRoutes({ routes }: { routes: IRoute[] }) {
  // 原地操作 routes 即可
  routesMap = generateRoutesMap(routes);
  if (Array.isArray(accessRouteKeys)) {
    const rootIndex = routes.findIndex((r) => r.path === '/');
    routes[rootIndex].children = routes[rootIndex].children
      .map((route: IRoute) => {
        if (['/', '/index'].includes(route.path) || route.path.startsWith('/account/')) {
          return route;
        }
        const nextRoutes = routesFilter([route], accessRouteKeys!);
        return nextRoutes[0];
      })
      .filter(Boolean);
  }
}

export function render(oldRender: any) {
  if (isDev) {
    oldRender();
  } else {
    if (window.location.pathname === loginPath) {
      oldRender();
    } else {
      getAccessPages().then((pages) => {
        if (pages == null) {
          oldRender();
        } else {
          accessRouteKeys = Array.isArray(pages) ? pages : Object.keys(pages);
          oldRender();
        }
      });
    }
  }
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState, setInitialState }) => {
  return {
    menuExtraRender: ({ collapsed }) =>
      !collapsed &&
      !initialState?.pathname?.startsWith('/index') && (
        <Space
          style={{
            marginBlockStart: 16,
          }}
          align="center"
        >
          <Input
            style={{
              borderRadius: 4,
              backgroundColor: '#FFF',
            }}
            prefix={<SearchOutlined />}
            placeholder="搜索页面"
            bordered={false}
            onPressEnter={(e) => {
              const menuKeyWord = (e.target as HTMLInputElement).value;
              setInitialState((prev) => ({ ...prev, menuKeyWord: menuKeyWord }));
            }}
          />
        </Space>
      ),
    postMenuData: (menus?: MenuDataItem[]) => {
      let nextMenus: MenuDataItem[] = menus || [];
      const base = initialState?.pathname?.split('/')[1];
      if (base && base !== 'index') {
        nextMenus = nextMenus?.filter((x) => x.path === '/' + base)?.[0]?.routes || [];
      } else {
        nextMenus = nextMenus.map((menu) => ({
          ...menu,
          routes: [],
          children: [],
        }));
      }
      return filterByMenuData(nextMenus, initialState?.menuKeyWord || '');
    },
    menuHeaderRender: (_logo, _title, props) => {
      if (props == null) {
        return null;
      }
      const dom = (
        <Space>
          <Link to="/index">
            <Button
              onClick={() => setInitialState((prev) => ({ ...prev, menuKeyWord: '' }))}
              size="small"
              icon={<LeftOutlined />}
            />
          </Link>
          <h3 style={{ color: '#FFF' }}>{initialState?.pathname?.split('/')[1]}</h3>
        </Space>
      );
      return !props?.collapsed && !initialState?.pathname?.startsWith('/index') ? dom : null;
    },
    headerContentRender: () => <HeaderContent />,
    rightContentRender: () => <RightContent />,
    waterMarkProps: {},
    footerRender: false,
    onPageChange: ({ pathname }) => {
      // 如果没有登录，重定向到 login
      if (
        !initialState?.currentUser &&
        window.location.pathname.replace(/\/+$/, '') !== loginPath
      ) {
        window.location.assign(
          `${window.location.origin}${loginPath}?redirect_url=${window.location.href}`,
        );
      }
      // 切换页面时统一清除 pageInfo 后重新拉取
      setInitialState((prev) => ({ ...prev, pageInfo: undefined, pathname }));
    },
    layoutBgImgList: [
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/D2LWSqNny4sAAAAAAAAAAAAAFl94AQBr',
        left: 85,
        bottom: 100,
        height: '303px',
      },
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/C2TWRpJpiC0AAAAAAAAAAAAAFl94AQBr',
        bottom: -68,
        right: -45,
        height: '303px',
      },
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/F6vSTbj8KpYAAAAAAAAAAAAAFl94AQBr',
        bottom: 0,
        left: 0,
        width: '331px',
      },
    ],
    links: [],
    // 自定义 403 页面
    // unAccessible: <div>unAccessible</div>,
    // 增加一个 loading 的状态
    childrenRender: (children) => {
      // if (initialState?.loading) return <PageLoading />;
      const pathname = window.location.pathname;
      if (routesMap[pathname] != null) {
        addRecentPage(pathname, routesMap[pathname]);
      }
      return (
        <>
          <PageBar
            key={window.location.pathname}
            axiosInstance={axios}
            updateInfo={(info: PageInfoModel) =>
              // add check path to check current state valid
              setInitialState((prev) => ({ ...prev, pageInfo: { ...info, check_path: pathname } }))
            }
          />
          {children}
        </>
      );
    },
    ...initialState?.settings,
  };
};

/**
 * @name request 配置，可以配置错误处理
 * 它基于 axios 和 ahooks 的 useRequest 提供了一套统一的网络请求和错误处理方案。
 * @doc https://umijs.org/docs/max/request#配置
 */
export const request: RequestConfig = {
  baseURL: '/api',
  ...errorConfig,
};
