import type { RequestOptions } from '@@/plugin-request/request';
import type { RequestConfig } from '@umijs/max';
import { message } from 'antd';

/**
 * @name 错误处理
 * pro 自带的错误处理， 可以在这里做自己的改动
 * @doc https://umijs.org/docs/max/request#配置
 */
export const errorConfig: RequestConfig = {
  errorConfig: {
    // 错误接收及处理
    errorHandler: (error: any, opts: any) => {
      if (opts?.skipErrorHandler) throw error;
      // 我们的 errorThrower 抛出的错误。
      if (error.response) {
        // Axios 的错误
        // 请求成功发出且服务器也响应了状态码，但状态代码超出了 2xx 的范围
        const status = error.response.status;
        const errorText = error.response.statusText;
        const detail =
          typeof error.response.data === 'string'
            ? error.response.data
            : error.response.data?.detail;
        if (status === 404 && error.config?.url?.endsWith('/info')) {
          // 拉取页面 info 404 不作处理
        } else if (status === 401 && detail === 'could not validate credentials') {
          window.location.assign(
            `${window.location.origin}/login?redirect_url=${window.location.href}`,
          );
        } else {
          if (Array.isArray(detail)) {
            const errorMessages = detail?.map((x) => x?.loc?.join(' ') + ' ' + x?.msg).join(', ');
            message.error(`${status}: ${errorMessages}`);
          } else {
            message.error(`${status}: ${detail || errorText}`);
          }
        }
      } else if (error.request) {
        // 请求已经成功发起，但没有收到响应
        // \`error.request\` 在浏览器中是 XMLHttpRequest 的实例，
        // 而在node.js中是 http.ClientRequest 的实例
        message.error('None response! Please retry.');
      } else {
        // 发送请求时出了点问题
        message.error('Request error, please retry.');
      }
    },
  },

  // 请求拦截器
  requestInterceptors: [
    (config: RequestOptions) => {
      // 拦截请求配置，进行个性化处理。
      const token: string = localStorage.getItem('token') || '';
      const headers = {
        ...config.headers,
        Authorization: 'Bearer ' + token,
      };
      return { ...config, headers };
    },
  ],
};
