import React, { useEffect, useRef } from 'react';

export function useInterval(
  callback: React.EffectCallback,
  delay: number | null = 600000,
) {
  const intervalRef = useRef<number | null>(null);
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay !== null) {
      intervalRef.current = window.setInterval(
        () => callbackRef.current(),
        delay,
      );
      return () => window.clearInterval(intervalRef.current || 0);
    }
    return;
  }, [delay]);

  return intervalRef;
}

export default useInterval;
