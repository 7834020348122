import { Space, Breadcrumb } from 'antd';
import React, { useContext } from 'react';
import { Link } from '@umijs/max';
import { RouteContext } from '@ant-design/pro-layout';

const GlobalHeaderRight: React.FC = () => {
  const value = useContext(RouteContext);
  const locale = value?.currentMenu?.locale;
  const matchMenuTitles = typeof locale === 'string' ? locale?.split('.')?.slice(2) : [];
  const matchMenuKeys = value?.matchMenuKeys?.slice(1);
  const matchMenuTitleLength = matchMenuTitles?.length || 0;
  const matchMenuKeyLength = matchMenuKeys?.length || 0;

  return (
    <Space size="large">
      <Breadcrumb>
        {matchMenuKeys?.slice(0, Math.min(matchMenuTitleLength, matchMenuKeyLength)).map((x, i) => (
          <Breadcrumb.Item key={x}>
            <Link to={x}>{matchMenuTitles[i]}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </Space>
  );
};

export default GlobalHeaderRight;
