import type { MenuDataItem } from '@ant-design/pro-layout';
import type { IRoute } from 'umi';

export function routesFilter(routes: IRoute[], accessPaths: string[]): IRoute[] {
  const checkedPaths: string[] = [];
  const checkedRoutes: IRoute[] = [];
  for (const route of routes) {
    if (Array.isArray(route.children)) {
      const childRoutes = routesFilter(route.children, accessPaths);
      // 只有叶子节点有权限，当前根茎节点才有权限
      if (childRoutes.filter((x) => x.path != null).length > 0) {
        checkedRoutes.push({ ...route, children: childRoutes });
      }
    } else {
      for (const accessPath of accessPaths) {
        if (accessPath.endsWith('*')) {
          if (
            route.path.startsWith(accessPath.slice(0, -1)) &&
            !checkedPaths.includes(route.path)
          ) {
            checkedPaths.push(route.path);
            checkedRoutes.push(route);
          }
        } else {
          if (route.path === accessPath && !checkedPaths.includes(route.path)) {
            checkedPaths.push(route.path);
            checkedRoutes.push(route);
          }
        }
      }
    }
  }
  return checkedRoutes;
}

export const filterByMenuData = (data: MenuDataItem[], keyWord: string): MenuDataItem[] =>
  data
    .map((item) => {
      if (
        (item.name && item.name.includes(keyWord)) ||
        (item.path && item.path.includes(keyWord)) ||
        filterByMenuData(item.children || [], keyWord).length > 0
      ) {
        return {
          ...item,
          children: filterByMenuData(item.children || [], keyWord),
        };
      }

      return undefined;
    })
    .filter((item) => item) as MenuDataItem[];

export const generateRoutesMap = (routes: IRoute[], parentNames: string[] = []) => {
  const routesMap: Record<string, string> = {};
  routes.forEach((route) => {
    if (route.path && route.name && route.routes == null) {
      routesMap[route.path] = [...parentNames, route.name].filter(Boolean).join(' / ');
    }
    if (Array.isArray(route.routes)) {
      Object.assign(routesMap, generateRoutesMap(route.routes, [...parentNames, route.name]));
    }
  });
  return routesMap;
};
