import { useModel } from '@umijs/max';
import { Alert, Space } from 'antd';
import React, { useState } from 'react';
import Avatar from './AvatarDropdown';
import styles from './index.less';

let annoIntervalFlag = false;

const GlobalHeaderRight: React.FC = () => {
  const [announcement, setAnnouncement] = useState('');
  const { initialState } = useModel('@@initialState');
  if (!initialState || !initialState.settings) {
    return null;
  }
  const { navTheme, layout } = initialState.settings;
  let className = styles.right;
  if (((navTheme === 'dark' || navTheme === 'realDark') && layout === 'top') || layout === 'mix') {
    className = `${styles.right}  ${styles.dark}`;
  }

  if (!annoIntervalFlag) {
    annoIntervalFlag = true;
    initialState.fetchAnnouncement?.().then((anno) => setAnnouncement(anno));
    setInterval(
      () => initialState.fetchAnnouncement?.().then((anno) => setAnnouncement(anno)),
      5 * 60 * 1000, // 5 minutes
    );
  }

  return (
    <Space className={className}>
      {announcement ? <Alert message={announcement} banner /> : null}
      <Avatar menu />
    </Space>
  );
};
export default GlobalHeaderRight;
